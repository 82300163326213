<template>
  <v-container class="flex">
    <v-row>
      <v-col>
        <v-card>
          <v-img src="@/assets/talkmHead.jpg"></v-img>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card-text class="text-center">
              <v-row dense>
                <v-col>
                  <v-text-field
                    v-model="mName"
                    :rules="mNameRules"
                    label="상호명"
                  />
                </v-col>
              </v-row>
              <!-- <v-text-field
                v-model="mCompany"
                :rules="mCompanyRules"
                label="상호명"
              /> -->
              <v-row dense>
                <!-- <v-col align-self="center" cols="auto">
                  <v-icon small color="green darken-2">
                    mdi-phone-classic
                  </v-icon>
                </v-col> -->
                <v-col cols="4">
                  <v-select
                    ref="rPhome1"
                    v-model="mPhome1"
                    :items="mPhomeItem"
                    menu-props="auto"
                    label="전화번호"
                  ></v-select>
                </v-col>
                <v-col align-self="center">
                  <p class="text-center Heading 5">-</p>
                </v-col>
                <v-col cols="3">
                  <!-- <v-text-field
                    :value="mPhome2"
                    @change="(v) => (mPhome2 = v)"
                  ></v-text-field> -->
                  <v-text-field
                    ref="rPhome2"
                    v-model="mPhome2"
                    :counter="4"
                    type="number"
                    min="0"
                    max="9999"
                    :rules="mPhome2Rules"
                    @keydown="keyPhome2"
                  >
                  </v-text-field>
                </v-col>
                <v-col align-self="center">
                  <p class="text-center Heading 5">-</p>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    ref="rPhome3"
                    v-model="mPhome3"
                    :counter="4"
                    type="number"
                    min="0"
                    max="9999"
                    :rules="mPhome3Rules"
                    @keydown="keyPhome3"
                  />
                </v-col>
              </v-row>
              <v-row>
                <!-- <v-col cols="5" align-self="center">
                  <p class="text-center Heading 5">지역선택(시/도)</p>
                </v-col> -->
                <v-col cols="6">
                  <v-select
                    ref="raddrcode1"
                    v-model="addrcode1"
                    :rules="maddrcode1Rules"
                    :items="gZIP_SIDOCODE"
                    item-text="SIDO"
                    item-value="SIDOCODE"
                    name="selectSIDO"
                    label="지역선택(시/도)"
                    return-object
                    @change="onzipsidicodeChange"
                  ></v-select>
                </v-col>
                <v-col cols="6">
                  <v-select
                    v-model="addrcode2"
                    :rules="maddrcode2Rules"
                    :items="gGE_ZIP_SIGUNGUCODE"
                    item-text="SIGUNGU"
                    item-value="SIGUNGUCODE"
                    label="지역선택(시/군/구)"
                    return-object
                    @change="onzipsigungucodeChange"
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider :inset="inset"></v-divider>
            <v-card-title class="grey lighten-4 flex">기본 서비스</v-card-title>
            <v-card-text class="text-center" dense>
              <v-radio-group v-model="radioGroup1" dense>
                <v-radio
                  label="IPM해충방제 2개월 무료 서비스"
                  color="success"
                  value="1"
                  dense
                  hide-details
                ></v-radio>
              </v-radio-group>
            </v-card-text>
            <v-divider :inset="inset"></v-divider>
            <v-card-title class="grey lighten-4 flex"
              >(무료)선택 서비스</v-card-title
            >
            <v-card-text class="text-center" dense>
              <v-radio-group v-model="radioGroup2" dense>
                <v-radio
                  label="내·외부 유리창 세척(2층 이상 매장은 내부 유리 세척만 가능)"
                  color="success"
                  value="1"
                  dense
                  hide-details
                ></v-radio>
                <v-radio
                  label="에어컨 필터 세척(최대 3대까지)"
                  color="success"
                  value="2"
                  dense
                  hide-details
                ></v-radio>
                <v-radio
                  label="에어컨 실외기 세척(최대 3대까지)"
                  color="success"
                  value="3"
                  dense
                  hide-details
                ></v-radio>
                <!-- <v-radio
                  label="냉장고 성애&먼지 제거 서비스(최대 1대)"
                  color="success"
                  value="4"
                  dense
                  hide-details
                ></v-radio> -->
              </v-radio-group>
            </v-card-text>
            <v-row dense>
              <v-col class="grey lighten-4 flex pl-5" cols="12">
                <v-checkbox
                  v-model="checkbox0"
                  dense
                  hide-details
                  color="success"
                  label="전체 동의하기"
                  @click="onCheckbox0"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-divider :inset="inset"></v-divider>

            <v-card-text class="text-center" dense>
              <v-row dense>
                <v-col cols="10">
                  <v-checkbox
                    v-model="checkbox1"
                    :rules="checkbox1Rules"
                    dense
                    hide-details
                    color="success"
                    label="개인정보 수집 및 이용 동의[필수]"
                  >
                  </v-checkbox>
                </v-col>
                <v-col cols="2">
                  <v-btn icon hide-details @click="onAgree1">
                    <v-icon small>mdi-eye-outline</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="10">
                  <v-checkbox
                    v-model="checkbox2"
                    :rules="checkbox2Rules"
                    dense
                    hide-details
                    color="success"
                    label="개인정보 제3자 제공 동의[필수]"
                  ></v-checkbox>
                </v-col>
                <v-col cols="2">
                  <v-btn icon hide-details @click="onAgree2">
                    <v-icon small>mdi-eye-outline</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>

            <div>
              <v-btn class="mt-8" color="success" block @click="validate">
                신청하기
              </v-btn>
            </div>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <dialog-msg />
    <dialog-agr />
    <ConfirmDlg ref="confirm" />
  </v-container>
</template>

<script>
import EventBus from "./EventBus";
import DialogMsg from "./dialogMessage.vue";
import DialogAgr from "./dialogAgree.vue";

import { mapActions, mapGetters } from "vuex";

const casezi = "casezi";
const code = "code";
const userStore = "userStore";

export default {
  name: "Successpack",
  components: {
    DialogMsg,
    DialogAgr,
    ConfirmDlg: () => import("./dialogawait"),
  },
  data() {
    return {
      valid: true,
      dialogB: false,
      mCPID: this.$store.getters["casezi/GE_CPID"],
      mSUB_NO: "",
      inset: false,
      mName: "",
      mCompany: "",
      mPhomeItem: [
        "010",
        "011",
        "016",
        "017",
        "018",
        "019",
        "02",
        "031",
        "032",
        "033",
        "041",
        "042",
        "043",
        "044",
        "051",
        "052",
        "053",
        "054",
        "055",
        "061",
        "062",
        "063",
        "064",
      ],
      mPhome1: "010",
      mPhome2: "",
      mPhome3: "",
      post1: "",
      addrcode1: "",
      addrcode2: "",
      address1: "",
      address2: "",
      radioGroup1: "1",
      radioGroup2: "1",
      checkbox0: false,
      checkbox1: false,
      checkbox2: false,
      mNameRules: [
        (v) => !!v || "필수 입력 항목입니다",
        (v) => v.length >= 2 || "이름을 입력하세요",
        (v) =>
          !/[`~!@#$%^&*()+|'";:{}/?]/.test(v) || "특수문자 입력할 수 없습니다.",
      ],
      mCompanyRules: [
        (v) => !!v || "필수 입력 항목입니다.",
        (v) => v.length >= 1 || "상호명을 입력하세요",
      ],

      mPhome1Rules: [
        (v) => !!v || "필수입력",
        (v) => (v.length >= 2 && v.length <= 3) || "입력길이",
        (v) => !/[^0-9]/.test(v) || "숫자입력",
      ],
      mPhome2Rules: [
        (v) => !!v || "필수입력",
        (v) => (v.length >= 2 && v.length <= 4) || "입력길이",
        (v) => !/[^0-9]/.test(v) || "숫자입력",
      ],
      mPhome3Rules: [
        (v) => !!v || "필수입력",
        (v) => (v && v.length == 4) || "입력길이",
        (v) => !/[^0-9]/.test(v) || "숫자입력",
      ],
      maddrcode1Rules: [(v) => !!v || "필수 입력 항목입니다"],
      maddrcode2Rules: [(v) => !!v || "필수 입력 항목입니다"],
      checkbox1Rules: [(v) => !!v || "필수 입력 항목입니다"],
      checkbox2Rules: [(v) => !!v || "필수 입력 항목입니다"],
    };
  },
  computed: {
    ...mapGetters(casezi, {
      gCPID: "GE_CPID",
      gSUB_NO: "GE_SUB_NO",
    }),
    ...mapGetters(code, {
      gZIP_SIDOCODE: "GE_ZIP_SIDOCODE",
      gGE_ZIP_SIGUNGUCODE: "GE_ZIP_SIGUNGUCODE",
    }),
  },
  watch: {
    mPhome2: function () {
      // this.watchPhome2(this.mPhome2);
      // this.mPhome2 = this.mPhome2.replace(/[^0-9]/g, "");
      // console.log("mPhome2 watch", this.mPhome2.length);
      if (this.mPhome2.length >= 4) {
        this.$refs.rPhome3.focus();
      }
    },
    mPhome3: function () {
      // this.mPhome3 = this.mPhome3.replace(/[^0-9]/g, "");
      if (this.mPhome3.length >= 4) {
        this.$refs.raddrcode1.focus();
      }
    },
  },
  created() {
    EventBus.$on("push-msg-ret", (payload) => {
      console.log(payload);
    });
  },
  mounted() {
    this.oninitZipCode();
    console.log("Parent mounted");
  },
  methods: {
    ...mapActions(code, ["ZIP_SIDOCODE", "ZIP_SIGUNGUCODE"]),
    ...mapActions(userStore, ["insertApplicants"]),
    validate() {
      this.valid = this.$refs.form.validate();
      // console.log("validate 결과", this.$refs.form.validate());
      // console.log("validate 결과", this.addrcode1);
      // console.log("validate 결과", this.addrcode2);
      if (this.valid) {
        this.onSubmit();
      } else {
        this.dialogHead = "info";
        this.dialogDesc = true;
        this.dialogMessage = "입력항목의 유효성을 확인 바랍니다.";

        this.clickBtn();
      }
    },
    clickBtn() {
      let payload = { Head: this.dialogHead, Message: this.dialogMessage };
      EventBus.$emit("push-msg", payload);
    },
    onAgree1() {
      let payload = { Desc: 1 };
      EventBus.$emit("push-Agree", payload);
    },
    onAgree2() {
      let payload = { Desc: 2 };
      EventBus.$emit("push-Agree", payload);
    },
    onCheckbox0() {
      if (this.checkbox0) {
        this.checkbox1 = true;
        this.checkbox2 = true;
      } else {
        this.checkbox1 = false;
        this.checkbox2 = false;
      }
    },
    async onSubmit() {
      this.mSUB_NO = this.mPhome1.concat(this.mPhome2.concat(this.mPhome3));
      this.mCompany = this.mName;
      // this.addrcode2 = "";
      // this.address2 = "";
      try {
        let Result = await this.insertApplicants({
          fun_code: "insert",
          cas_id: this.mCPID,
          sub_no: this.mSUB_NO,
          admin_name: this.mName,
          company_name: this.mName,
          tel_number1: this.mPhome1,
          tel_number2: this.mPhome2,
          tel_number3: this.mPhome3,
          addrcode1: this.addrcode1,
          addrcode2: this.addrcode2,
          address1: this.address1,
          address2: this.address2,
          service1: this.radioGroup1,
          service2: this.radioGroup2,
          privacycollection: this.checkbox1,
          privacyThirdparty: this.checkbox2,
        });
        if (Result.data.repCode == "00") {
          this.dialogHead = "info";
          this.dialogDesc = true;
          this.dialogMessage =
            "U+ 사장님광장 고객 전용 해충방제 2개월 무료 이벤트 신청이 완료되었습니다. 서비스 상담원이 순차적으로 연락드릴 예정입니다.";

          if (
            await this.$refs.confirm.open(
              "Confirm",
              "U+ 사장님광장 고객 전용 해충방제 2개월 무료 이벤트 신청이 완료되었습니다. 서비스 상담원이 순차적으로 연락드릴 예정입니다."
            )
          ) {
            console.log("confirm 결과");
            this.goToPages();
          }
        } else if (Result.data.repCode == "01") {
          this.dialogHead = "info";
          this.dialogDesc = true;
          this.dialogMessage = Result.data.repMessage;
          this.clickBtn();
        } else {
          this.dialogHead = "Error";
          this.dialogDesc = true;
          this.dialogMessage =
            "처리중 오류가 발생하였습니다. \n" + Result.data.repMessage;
          this.clickBtn();
        }
      } catch (err) {
        console.error(err);
      }
    },
    async onzipsidicode() {
      try {
        const Result = await this.ZIP_SIDOCODE({
          fun_code: "sidocode",
        });
        if (Result) {
          this.addrcode1 = Result.data[0].SIDOCODE;
          this.address1 = Result.data[0].SIDO;
          console.log("onzipsidicode 결과", Result);
          await this.onzipsidicodeChange(Result.data[0]);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async onzipsidicodeChange(value) {
      try {
        this.addrcode1 = value.SIDOCODE;
        this.address1 = value.SIDO;
        console.log("onzipsidicodeChange1 결과", value);
        const Result = await this.ZIP_SIGUNGUCODE({
          fun_code: "sigungucode",
          SIDO: value.SIDOCODE,
        });
        if (Result) {
          this.addrcode2 = Result.data[0].SIGUNGUCODE;
          this.address2 = Result.data[0].SIGUNGU;
          console.log("onzipsidicodeChange2 결과", Result);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async onzipsigungucodeChange(value) {
      this.addrcode2 = value.SIGUNGUCODE;
      this.address2 = value.SIGUNGU;
      console.log("onzipsigungucodeChange 결과", value);
    },
    async oninitZipCode() {
      try {
        let Result = await this.onzipsidicode();
        console.log("oninitZipCode1 결과", Result);
      } catch (err) {
        console.error(err);
      }
    },
    goToPages() {
      this.$router.push({
        name: "tomato758",
      });
    },
    watchPhome2(value) {
      console.log(value);
    },
    keyPhome2(event) {
      console.log(event);
    },
    keyPhome3(event) {
      console.log(event);
    },
  },
};
</script>

<style>
#input {
  border-bottom: 1px solid Gray;
}
</style>
